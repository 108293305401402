import { ETATS_CHECKBOXES_COLUMNS } from '../../../../utils/constants'
import { getReferenceDependencies } from '../../../common/model/modelSelectors'
import { getAllFieldsChecked, isChecked } from './tabDocumentSelector'
import { getTabDocuments, updateField } from './tabDocumentApi'
import { FIELD_TYPE } from '../../document/utils/documentConstants'
import { getAllItemsByListCode } from '../../../common/valueList/valueListSelectors'

const MAX_FIELDS = 50

/**
 * Requête de remplissage du tableau
 * @param page numéro de page
 * @param params paramètres de la recherche
 * @param isConsultation vue consultation
 * @param targetLanguage langue souhaitée
 */
export const getDataTable = (page, params, isConsultation, targetLanguage) => (dispatch, state) => {
	const columnsSelected = getAllFieldsChecked(state())

	if (Object.keys(columnsSelected).length > MAX_FIELDS) {
		return Promise.reject()
	}
	return getTabDocuments(page, params, isConsultation, targetLanguage, Object.keys(columnsSelected))
		.then(res => {
			dispatch(setDataTable({
				page: page,
				size: res.results.length,
				total: res.count,
				content: res.results,
				selectedColumns: res.selectedColumns
			}))
		})
		.catch(() => dispatch(resetDataTable()))
}

export const UPDATE_ONE_FIELD = 'UPDATE_ONE_FIELD'
/**
 * Update d'un champ :
 * 1. Dans le store
 * 2. En BD + Ré-indexation (Transactionnal)
 * @param idRow - l'id de la row (du document)
 * @param codeColumn - le code de la colonne à modifier
 * @param newValue - la nouvelle valeur du champ
 * @param idField - l'id du field à modifier en base
 * @param idLanguage - l'id de la langue dans laquelle il faut modifier la valeur en base
 * @param type - le type du field
 * @param unite - l'unité du champ NUMBER_UNIT sinon null
 * @param valueListShortcut - le code de la valueList
 */
export const updateOneField = (idRow, codeColumn, newValue, idField, idLanguage, type, unite, valueListShortcut) => (dispatch, state) => {
	let newValueArray
	let valueForStoreUpdate = newValue
	let newCodesInStore = ''
	if (type === FIELD_TYPE.MULTI_SELECT) {
		newCodesInStore = `[${newValue.map(code => `"${code}"`)}]`
		// Ici on traduit les codes venant du multi-select en label pour mettre à jour le store
		const valueLists = getAllItemsByListCode(state())
		const labels = newValue.map(valueCode => `"${valueLists[valueListShortcut].find(entry => entry.code === valueCode).label}"`)
		valueForStoreUpdate = `[${labels}]`
		newValueArray = newValue
		newValue = ''
	} else if (type === FIELD_TYPE.SELECT) {
		// Ici on traduit les codes venant du select en label pour mettre à jour le store
		const valueLists = getAllItemsByListCode(state())
		valueForStoreUpdate = valueLists[valueListShortcut].find(entry => entry.code === newValue).label
	}

	return updateField(idRow, idField, newValue, idLanguage, codeColumn, type, unite, newValueArray)
		.then(() => {
			// On ne mets à jour le store que lorsque la modification en base est ok
			// Sinon : Perte de connexion -> store local non ISO avec la BD + Elastic
			dispatch({ type: UPDATE_ONE_FIELD, idRow, codeColumn, newValue: valueForStoreUpdate, idField, newCodesInStore })
		})
		.catch(e => {
			throw e
		})
}

export const SET_DATA = 'SET_DATA'
export const setDataTable = (data) => dispatch => {
	dispatch({ type: SET_DATA, payload: data })
}

export const OPEN_POPIN = 'OPEN_POPIN'
export const openPopin = () => dispatch => {
	dispatch({ type: OPEN_POPIN })
}

export const CANCEL_POPIN = 'CANCEL_POPIN'
export const cancelPopin = () => dispatch => {
	dispatch({ type: CANCEL_POPIN })
}

/**
 * Reset des data
 */
export const RESET_DATA = 'RESET_DATA'
export const resetDataTable = () => dispatch => {
	dispatch({ type: RESET_DATA })
}

export const FIELD_LIST_CHECKED = 'FIELD_LIST_CHECKED'
export const SECTION_LIST_CHECKED = 'SECTION_LIST_CHECKED'
export const SECTION_LIST_UPDATE_CHECK = 'SECTION_LIST_UPDATE_CHECK'
export const FIELD_LIST_UNCHECKED = 'FIELD_LIST_UNCHECKED'
export const SECTION_LIST_UNCHECKED = 'SECTION_LIST_UNCHECKED'
export const updateChecked = (id, status, isSection, parentIds) => (dispatch, state) => {
	dispatch(updateStatusCheckboxes([id], status, isSection))

	// On va update en récursif les sections/champs
	const referenceDependencies = getReferenceDependencies(state())
	if (isSection) {
		// Pour une section, on récupère tous ses fils (sections, champs) et on les met à jour
		if (referenceDependencies[id]) {
			const idsToUpdate = getIdsFromSectionToUpdate(referenceDependencies[id].sectionsChildren.map(child => child.id), referenceDependencies)
			dispatch(updateStatusCheckboxes([...idsToUpdate.fieldIds, ...((referenceDependencies[id].fieldsChildren || []).map(child => child.id))], status, false))
			dispatch(updateStatusCheckboxes([...idsToUpdate.sectionIds, ...((referenceDependencies[id].sectionsChildren || []).map(child => child.id))], status, true))
		}
	}

	// On actualise ensuite le dessus
	let formattedStatus = status ? ETATS_CHECKBOXES_COLUMNS.CHECKED : ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED
	let isIndeterminated = false
	const parents = parentIds.reverse()

	if (parents.length > 0) {
		// On démarre par la section parent du champ/section saisi
		const firstParent = parents[0]
		if (referenceDependencies[firstParent]) {
			// On regarde si tous les champs à ce niveau ont le même statut
			const allFieldsWithSameStatus = referenceDependencies[firstParent].fieldsChildren
				.filter(field => (!isSection && field.id !== id) || isSection)
				.every(field => (formattedStatus === ETATS_CHECKBOXES_COLUMNS.CHECKED && isChecked(state(), field.id, false) === ETATS_CHECKBOXES_COLUMNS.CHECKED)
					|| (formattedStatus === ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED && !isChecked(state(), field.id, false))
				)

			if (!allFieldsWithSameStatus) {
				// Si nous sommes en indéterminé, on s'arrête et set toutes les autres sections en indéterminé
				dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: parents, status: ETATS_CHECKBOXES_COLUMNS.INDETERMINATE })
				isIndeterminated = true
			} else {
				const allSectionsWithSameStatus = referenceDependencies[firstParent].sectionsChildren
					.filter(section => (isSection && section.id !== id) || !isSection)
					.every(section => (formattedStatus === ETATS_CHECKBOXES_COLUMNS.CHECKED && isChecked(state(), section.id, true) === ETATS_CHECKBOXES_COLUMNS.CHECKED)
						|| (formattedStatus === ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED && !isChecked(state(), section.id, true)))

				if (!allSectionsWithSameStatus) {
					// Si nous sommes en indéterminé sur les fields, on s'arrête et set toutes les autres sections en indéterminé
					dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: parents, status: ETATS_CHECKBOXES_COLUMNS.INDETERMINATE })
					isIndeterminated = true
				} else {
					dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: [firstParent], status: formattedStatus })
				}
			}
		}

		// On remonte ensuite chaque section si non indéterminé
		let childId = parents[0]
		const parentsArray = parents.splice(1)
		let i = 0
		while (!isIndeterminated && i < parentsArray.length) {
			const parentId = parentsArray[i]
			if (referenceDependencies[parentId]) {
				// On regarde si tous les champs à ce niveau ont le même statut
				const allFieldsWithSameStatus = referenceDependencies[parentId].fieldsChildren
					.every(field => (formattedStatus === ETATS_CHECKBOXES_COLUMNS.CHECKED && isChecked(state(), field.id, false) === ETATS_CHECKBOXES_COLUMNS.CHECKED)
						|| (formattedStatus === ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED && !isChecked(state(), field.id, false)))

				if (!allFieldsWithSameStatus) {
					// Si nous sommes en indéterminé sur les fields, on s'arrête et set toutes les autres sections en indéterminé
					dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: parentsArray.splice(i), status: ETATS_CHECKBOXES_COLUMNS.INDETERMINATE })
					isIndeterminated = true
				} else {
					const allSectionsWithSameStatus = referenceDependencies[parentId].sectionsChildren
						.filter(section => section.id !== childId)
						.every(section => (formattedStatus === ETATS_CHECKBOXES_COLUMNS.CHECKED && isChecked(state(), section.id, true) === ETATS_CHECKBOXES_COLUMNS.CHECKED)
							|| (formattedStatus === ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED && !isChecked(state(), section.id, true)))

					if (!allSectionsWithSameStatus) {
						// Si nous sommes en indéterminé sur les fields, on s'arrête et set toutes les autres sections en indéterminé
						dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: parentsArray.splice(i), status: ETATS_CHECKBOXES_COLUMNS.INDETERMINATE })
						isIndeterminated = true
					} else {
						dispatch({ type: SECTION_LIST_UPDATE_CHECK, ids: [parentId], status: formattedStatus })
					}
				}
			}
			i++
		}
	}
}

export const SECTION_ADVANCED_CHECKED = 'SECTION_ADVANCED_CHECKED'
export const SECTION_ADVANCED_UNCHECKED = 'SECTION_ADVANCED_CHECKED'
export const updateCheckedAdvanced = (id, status, isSection, parentIds) => (dispatch, state) => {
	const formattedStatus = status ? ETATS_CHECKBOXES_COLUMNS.CHECKED : ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED

	// Mise à jour de l'élément cliqué
	if (isSection) {
		dispatch({ type: SECTION_ADVANCED_CHECKED, id: id, status: formattedStatus })
	} else {
		dispatch(updateStatusCheckboxes([id], status, false))
	}

	// Dans le cas d'un champ avancé, seul le noeud avancé est à écouter/modifier
	const referenceDependencies = getReferenceDependencies(state())
	if (isSection) {
		// Pour une section, on récupère les fields avancés enfant et on set leur statut
		if (referenceDependencies[id] && referenceDependencies[id].fieldsAdvancedChildren) {
			dispatch(updateStatusCheckboxes(referenceDependencies[id].fieldsAdvancedChildren.map(child => child.id), status, false))
		}
	} else {
		// On récupère la section parent et regarde tous les champs
		// Même statut -> on set ce statut
		// Sinon -> Indéterminé
		const idSectionParent = parentIds[parentIds.length - 1]
		if (referenceDependencies[idSectionParent] && referenceDependencies[idSectionParent].fieldsAdvancedChildren) {
			const allWithSameStatus = referenceDependencies[idSectionParent].fieldsAdvancedChildren
				.filter(field => field.id !== id)
				.every(field => (formattedStatus === ETATS_CHECKBOXES_COLUMNS.CHECKED && isChecked(state(), field.id, false) === ETATS_CHECKBOXES_COLUMNS.CHECKED)
					|| (formattedStatus === ETATS_CHECKBOXES_COLUMNS.NOT_CHECKED && !isChecked(state(), field.id, false)))

			if (allWithSameStatus && !status) {
				dispatch({ type: SECTION_ADVANCED_UNCHECKED, id: idSectionParent })
			} else {
				dispatch({ type: SECTION_ADVANCED_CHECKED, id: idSectionParent, status: allWithSameStatus ? ETATS_CHECKBOXES_COLUMNS.CHECKED : ETATS_CHECKBOXES_COLUMNS.INDETERMINATE })
			}
		}
	}
}

export const initColumnsSelected = () => (dispatch, state) => {
	const dependencies = getReferenceDependencies(state())
	const initId = Object.keys(dependencies)[0]
	dispatch(updateStatusCheckboxes([initId], ETATS_CHECKBOXES_COLUMNS.CHECKED, true))
	if (dependencies[initId] && dependencies[initId].fieldsChildren) {
		dispatch(updateStatusCheckboxes(dependencies[initId].fieldsChildren.map(child => child.id), ETATS_CHECKBOXES_COLUMNS.CHECKED, false))
	}
	return Promise.resolve()
}

const findParents = (hierarchieFieldSections, idSectionMere, dependencies) => {
	const idSectionParent = Object.entries(dependencies).find(([key, value]) => value.sectionsChildren.find(child => child.id === idSectionMere))
	if (idSectionParent != null) {
		hierarchieFieldSections.push(idSectionParent[0])
		findParents(hierarchieFieldSections, idSectionParent[0], dependencies)
	}
}

export const initColumnsSelectedFromSharedSearch = (tabIdFieldsChecked) => (dispatch, state) => {
	const dependencies = getReferenceDependencies(state())
	tabIdFieldsChecked.forEach(idField => {
		const hierarchie = []
		const parentField = Object.entries(dependencies).find(([key, value]) => value.fieldsChildren.find(field => field.id === idField))
		// Si on a des parents (et que le champ existe)
		// Pas de parent -> le field n'existe plus
		if (parentField !== null && parentField !== undefined)   {
			const parentId = parentField[0]
			hierarchie.push(parentId)
			findParents(hierarchie, parentId, dependencies)
			dispatch(updateChecked(idField, ETATS_CHECKBOXES_COLUMNS.CHECKED, false, hierarchie))
		}
	})
}

export const CLEAR_COLUMNS_SELECTED = 'CLEAR_COLUMNS_SELECTED'
export const clearColumnsSelected = () => dispatch => {
	dispatch({ type: CLEAR_COLUMNS_SELECTED })
}

/**
 * Fonction qui send la modification des status au reducer
 * @param ids ids à modifier
 * @param status nouveau statut
 * @param isSection est-ce une section ?
 */
const updateStatusCheckboxes = (ids, status, isSection) => dispatch => {
	if (status) {
		dispatch({ type: isSection ? SECTION_LIST_CHECKED : FIELD_LIST_CHECKED, ids })
	} else {
		dispatch({ type: isSection ? SECTION_LIST_UNCHECKED : FIELD_LIST_UNCHECKED, ids })
	}
}

const initIdsFromSection = {
	sectionIds: [],
	fieldIds: []
}


/**
 * Fonction qui send la modification des status au reducer pour les collapsed
 * @param ids ids à modifier
 * @param status nouveau statut
 */
export const COLLAPSED_LIST_TRUE = 'COLLAPSED_LIST_TRUE'
export const COLLAPSED_LIST_FALSE = 'COLLAPSED_LIST_FALSE'
export const updateStatusCollapse = (id, status) => dispatch => {
	status ? dispatch({ type: COLLAPSED_LIST_TRUE, id }) : dispatch({ type: COLLAPSED_LIST_FALSE, id })
}

/**
 * Fonction de récupération des ids à mettre à jour à partir d'une section parent
 * @param sectionIds
 * @param referenceDependencies
 * @returns {*}
 */
const getIdsFromSectionToUpdate = (sectionIds, referenceDependencies) => {
	return sectionIds.reduce((acc, sectionId) => {
		const result = {
			sectionIds: [],
			fieldIds: []
		}
		if (referenceDependencies[sectionId] && referenceDependencies[sectionId].sectionsChildren) {
			result.sectionIds.push(...referenceDependencies[sectionId].sectionsChildren.map(child => child.id))

			const children = getIdsFromSectionToUpdate(referenceDependencies[sectionId].sectionsChildren.map(child => child.id), referenceDependencies)
			result.sectionIds.push(...children.sectionIds)
			result.fieldIds.push(...children.fieldIds)
		}
		if (referenceDependencies[sectionId] && referenceDependencies[sectionId].fieldsChildren) {
			result.fieldIds.push(...referenceDependencies[sectionId].fieldsChildren.map(child => child.id))
		}

		return {
			sectionIds: [...acc.sectionIds, ...result.sectionIds],
			fieldIds: [...acc.fieldIds, ...result.fieldIds]
		}
	}, initIdsFromSection)
}
